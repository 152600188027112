'use client';

import { type PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import type { Organization } from '@/__generated__/graphql';

type ImitatedOrganizationPayload = {
  alias: string;
};

type CurrentProjectPayload = {
  displayName: string;
  isPrivate: boolean;
} | null;

const locallyImitatedOrganizationKey = '_lio';

export const initialGlobalState = {
  imitatedOrganization: {
    alias: '',
  },
  currentProject: null as CurrentProjectPayload,
  setCurrentProject: (() => {}) as (arg0: CurrentProjectPayload) => void,
  setImitatedOrganization: (() => {}) as (arg0: ImitatedOrganizationPayload) => void,
  organizationsMeta: [] as Organization[],
  setOrganizationsMeta: (() => {}) as (arg0: Organization[]) => void,
};

export const GlobalContext = createContext(initialGlobalState);

// Simple global state for app, to avoid redux clutter.
export function GlobalContextProvider({ children }: PropsWithChildren) {
  const [currentProject, setCurrentProject] = useState<CurrentProjectPayload>(null);
  const [imitatedOrganization, setImitatedOrganization] = useState({
    alias: '',
  });
  const [organizationsMeta, setOrganizationsMeta] = useState<Organization[]>([]);

  const setCurrentProjectHandler = useCallback(async (project: CurrentProjectPayload) => {
    setCurrentProject(project);
  }, []);

  const setImitatedOrganizationHandler = useCallback(({ alias }: ImitatedOrganizationPayload) => {
    localStorage.setItem(locallyImitatedOrganizationKey, alias);

    setImitatedOrganization(prev => ({
      ...prev,
      alias,
    }));
  }, []);

  const values = useMemo(
    () => ({
      imitatedOrganization,
      setImitatedOrganization: setImitatedOrganizationHandler,
      currentProject,
      setCurrentProject: setCurrentProjectHandler,
      organizationsMeta,
      setOrganizationsMeta,
    }),
    [imitatedOrganization, setImitatedOrganizationHandler, organizationsMeta, currentProject, setCurrentProjectHandler],
  );

  useEffect(() => {
    const locallyImitatedOrganization = localStorage.getItem(locallyImitatedOrganizationKey);

    if (locallyImitatedOrganization) {
      const alias = locallyImitatedOrganization;

      setImitatedOrganization(prev => ({ ...prev, alias }));
    }
  }, []);

  return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>;
}
