'use client';

import { childrenWithoutLayout } from '@/constants/constant';
import { GlobalContextProvider } from '@/context/GlobalContext';
import GraphQLApolloProvider from '@/hooks/GraphQLApolloProvider';
import { UserPermissionsProvider } from '@/providers/UserPermissionsProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import { Toaster } from '@repo/ui/components/Toaster';
import type { AppState } from '@auth0/auth0-react';
import { usePathname, useRouter } from 'next/navigation';
import { PostHogProvider } from 'posthog-js/react';
import { Suspense, useMemo } from 'react';
import posthog from 'posthog-js';
import type { RootPageProps } from '@/types/RootPageProps';
import { RouterLayout } from './RouterLayout';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    capture_pageview: false, // captured manually
  });
}

export function ProviderLayout({ children, params, searchParams }: RootPageProps) {
  const router = useRouter();
  const pathname = usePathname();
  const isBrowser = typeof window !== 'undefined';
  const domain = process.env.AUTH0_DOMAIN || '';
  const clientId = process.env.AUTH0_CLIENT_ID || '';
  const audience = process.env.GRAPHQL_AUDIENCE_IDENTIFIER || '';
  const issuer = process.env.AUTH0_ISSUER || '';
  const redirect_uri = isBrowser ? window.location.origin : '/here';

  // Check for error

  const onRedirectCallback = useMemo(
    () => (appState: AppState | undefined) => {
      const searchParams = new URLSearchParams(window.location.search);
      const errorDescription = searchParams.get('error_description');
      const error = searchParams.get('error');

      if (error === 'invalid_request' && errorDescription?.includes('organization membership')) {
        console.error('Organization membership error:', error, errorDescription);
        return router.replace('/invite_error');
      }

      if (error || errorDescription) {
        console.error('Auth error:', error, errorDescription);
        return router.replace('/error');
      }

      router.replace('/');
    },
    [router],
  );

  const childWithoutLayout = childrenWithoutLayout.find(path => path === pathname);

  return (
    <>
      <GlobalContextProvider>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          issuer={issuer}
          authorizationParams={{
            // eslint-disable-next-line camelcase
            redirect_uri,
            audience,
            scope:
              // eslint-disable-next-line max-len
              'openid profile email global_management_all_entities:all_tasks organization_management_all_entities:all_tasks organization_management_all_entities:read organization_management_analytics:read global_dashboard_analytics:write create:organization_invitations',
          }}
          // cacheLocation="localstorage"
          // useRefreshTokens={true}
          onRedirectCallback={onRedirectCallback}
        >
          <UserPermissionsProvider>
            <Suspense>
              <GraphQLApolloProvider>
                <PostHogProvider client={posthog}>
                  {!childWithoutLayout ? <RouterLayout posthog={posthog}>{children}</RouterLayout> : <>{children}</>}
                </PostHogProvider>
              </GraphQLApolloProvider>
            </Suspense>
          </UserPermissionsProvider>
        </Auth0Provider>
        <Toaster />
      </GlobalContextProvider>
    </>
  );
}
