'use client';

/* eslint-disable react/function-component-definition */
import { ApolloClient, ApolloLink, type NormalizedCacheObject, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import React, { useState, useEffect, type ReactNode, useContext } from 'react';
import { useParams, useSearchParams } from 'next/navigation';
// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { GlobalContext } from '@/context/GlobalContext';
import { useUserPermissionsContext } from '@/providers/UserPermissionsProvider';
import { GET_ALL_ORG_META } from '@/schemes';
import SkeletonGlobal from '@/components/Skeletons/SkeletonGlobal';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureException(new Error(message), {
        extra: {
          locations,
          path,
        },
      });
    });
  }
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

interface GraphQLApolloProviderProps {
  children: ReactNode;
}

const GraphQLApolloProvider: React.FC<GraphQLApolloProviderProps> = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const { imitatedOrganization, setOrganizationsMeta } = useContext(GlobalContext);
  const { isGlobalAdministrator } = useUserPermissionsContext();
  const params = useParams();
  const searchParams = useSearchParams();
  const accessDenied = searchParams.get('error') === 'access_denied';
  const { organizationAlias } = params;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <works as expected>
  useEffect(() => {
    const imitatedOrganizationAlias = isGlobalAdministrator
      ? organizationAlias
      : user?.['https://inkeep.com:imitated_org_alias'] || undefined;

    if (user?.['https://inkeep.com:imitated_organization_id'] || accessDenied) {
      logout();
      return;
    }

    const createApolloClient = async () => {
      // Also includes http link
      const uploadLink = createUploadLink({ uri: process.env.GRAPHQL_ENDPOINT });
      const authLink = setContext(async (_, { headers }) => {
        let token = '';

        try {
          token = await getAccessTokenSilently();
        } catch (error) {
          console.error(`Access token acquisition failed: ${error}`);
          logout();
          throw error;
        }

        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
            'Imitated-Organization-Alias': imitatedOrganizationAlias,
          },
        };
      });

      setClient(
        new ApolloClient({
          link: ApolloLink.from([errorLink, authLink, uploadLink]),
          cache: new InMemoryCache(),
          connectToDevTools: process.env.NODE_ENV === 'development',
        }),
      );
    };

    createApolloClient();
  }, [getAccessTokenSilently, user, imitatedOrganization, logout, organizationAlias, isGlobalAdministrator]);

  useEffect(() => {
    if (client && isGlobalAdministrator) {
      client
        .query({
          query: GET_ALL_ORG_META,
        })
        .then(({ data }) => setOrganizationsMeta(data.allOrganizationsMetadata));
    }
  }, [client, isGlobalAdministrator, setOrganizationsMeta]);

  if (!client) {
    return <SkeletonGlobal />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLApolloProvider;
