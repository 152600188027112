'use client';

import { type PropsWithChildren, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { usePostHog } from 'posthog-js/react';
import { GET_USER } from '@/schemes';
import { useQuery } from '@apollo/client';
import NavBar from '@/components/Nav/NavBar';
import useRoutingListener from '@/hooks/useRoutingListener';
import useRemoveDarkModeFromLocalStorage from '@/hooks/useRemoveDarkMode';

function AuthenticationLayout({ children }: PropsWithChildren<{}>) {
  const { user } = useAuth0();

  if (!user) {
    throw new Error('User not found');
  }

  const { data } = useQuery(GET_USER, {
    variables: { userId: user.sub! },
  });

  useRoutingListener();
  // if we ever want to support dark mode again we should remove this
  useRemoveDarkModeFromLocalStorage();

  const posthog = usePostHog();

  Sentry.setUser({
    email: data?.user?.email && data?.user?.emailVerified ? data?.user?.email : undefined,
    username: data?.user?.name || undefined,
  });

  useEffect(() => {
    if (data) {
      posthog.identify(data?.user?.id || undefined, {
        email: data?.user?.email && data?.user?.emailVerified ? data?.user?.email : undefined,
        name: data?.user?.name || undefined,
      });
    }
  }, [data, posthog]);

  if (!data) {
    return <div className="bg-[rgb(31,31,31)]" />;
  }

  return (
    <div className="border-gray-200 dark:bg-grayDark-800 dark:border-grayDark-600 font-inter">
      <NavBar />
      <section className="flex h-[calc(100vh-64px)] overflow-hidden">{children}</section>
    </div>
  );
}

export default ({
  organization,
  invitation,
  children,
}: {
  organization: string | undefined;
  invitation: string | undefined;
  children: any;
}) => {
  if (invitation) {
    console.log(`Invitation being redeemed!: ${invitation}, Organization: ${organization}`);
  }

  return withAuthenticationRequired(AuthenticationLayout, {
    loginOptions: {
      authorizationParams: {
        organization,
        invitation,
      },
    },
  })({ children });
};
