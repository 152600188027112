'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import type { PostHog } from 'posthog-js';
import { useEffect } from 'react';
import AuthenticationLayout from './AuthenticationLayout';

export function RouterLayout({ children, posthog }: { children: React.ReactNode; posthog: PostHog }) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const err = searchParams.get('error');
  const router = useRouter();
  useEffect(() => {
    if (err) {
      router.replace('/error');
    }
  }, [router, err]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleRouteChange = () => posthog?.capture('$pageview');
    handleRouteChange();
    return () => {
      handleRouteChange();
    };
  }, [searchParams, pathname]);
  return (
    <AuthenticationLayout
      invitation={searchParams.get('invitation') ?? undefined}
      organization={searchParams.get('organization') ?? undefined}
    >
      {children}
    </AuthenticationLayout>
  );
}
